import { Link } from 'gatsby'
import React from 'react'
import { Accordion } from '../general/accordion'
import styles from './publicContents.module.scss'
import accentLogo from '@/images/accent.svg'
import accentLogo2 from '@/images/accent2.svg'
import acumen from '@/images/acumen-img.png'
import acumen1 from '@/images/acumen1.png'
import acumen2 from '@/images/acumen2.png'
import acumen3 from '@/images/acumen3.png'
import interviewer1 from '@/images/interviewer1.png'
import interviewer2 from '@/images/interviewer2.png'
import thumbnail from '@/images/module_thumbnail.png'

export const PublicContents: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Accordion title={'Cognition Cafe Reserve'}>
        <div className={styles.menuBody}>
          <div className={styles.description}>
            <img src={thumbnail} alt="" className={styles.thumbnail} />
            <p className={styles.descriptionText}>
              <span className={styles.title}>
                認知機能に関するマエストロを目指してもらえるようにより専門的なコンテンツを紹介していきます。
              </span>
              Advancing Cognition Understanding in MENtal illness (ACUMEN)by the
              World Federation of Societies of Biological Psychiatry (WFSBP)
            </p>
          </div>

          <div className={styles.introduceProgramChair}>
            <p className={styles.title}>プログラムチェアーのご紹介</p>

            <div className={styles.introduces}>
              <div className={styles.introduceBlock}>
                <img src={interviewer1} alt="" className={styles.interviewer} />
                <img
                  src={accentLogo}
                  alt=""
                  className={styles.accentInterviewer1}
                />
                <p className={styles.introduceDescription}>
                  <span className={styles.name}>
                    Lakshmi N. Yatham 教授
                    (ブリティッシュコロンビア大学・カナダ)
                  </span>
                  Lakshmi
                  N.Yatham教授は、カナダのバンクーバーにあるブリティッシュコロンビア大学精神医学科の精神医学教授です。現在は
                  WFSBPの会長 兼Canadian Journal of Psychiatryの編集長。
                  <br />
                  また精神医学の地域責任者であり、バンクーバーコースタルヘルスアンドプロビデンスヘルスケアのメンタルヘルスと依存症の地域プログラムメディカルディレクター。
                  <br />
                  サウダービジネススクールにてヘルスケアのエグゼクティブMBAを取得、国際双極性障害学会の会長、世界生物学的精神医学会連合
                  (WFSBP)
                  の事務局長など、国内および国際的な専門組織の指導的お立場としてご活躍されています。
                </p>
              </div>
              <div className={styles.introduceBlock2}>
                <p className={styles.introduceDescription}>
                  <span className={styles.name}>
                    Eduard Vieta 教授 (バルセロナ大学・スペイン)
                  </span>
                  Eduard
                  Vieta教授は、バルセロナ大学の精神医学教授です。現在、スペインのメンタルヘルス研究ネットワーク(CIBERSAM)
                  の科学ディレクターでありながら、ホスピルクリニックにおける精神医学および心理学科の主任、双極性障害の臨床ケア、教育、研究の世界的リーダーのお一人です。スペインで最も優れた精神科医(Monitor
                  sanitario、El Español)
                  に2度も選出、バレンシア大学から名誉博士にも選ばれております。マクリーン病院とハーバード大学の招聘教授、欧州大統領府の神経科学科学顧問を務められたご経験から現在、欧州神経精神薬理学会
                  (ECNP) の 会計係を務めおり、過去
                  8年間で双極性障害の分野で世界で最も引用された科学者として世界で最も影響力のある科学者のお一人としてご活躍されています。
                </p>
                <img src={interviewer2} alt="" className={styles.interviewer} />
                <img
                  src={accentLogo}
                  alt=""
                  className={styles.accentInterviewer2}
                />
              </div>
            </div>
          </div>

          <div className={styles.aboutAcumen}>
            <p className={styles.title}>ACUMENとは</p>
            <div className={styles.acumenBlock}>
              <img src={acumen} alt="" className={styles.acumenImg} />
              <p className={styles.detail}>
                <span className={styles.overview}>
                  WFSBPが作成した14モジュールからなる認知機能に関するe-learning
                  プログラムで、EACCME®が認証した生涯教育用コンテンツです。
                </span>
                Yatham
                教授およびVieta教授が、統合失調症および双極性障害の認知機能領域の世界的専門家にインタビューし、実臨床に役立つ最先端の内容が紹介されます。
              </p>
            </div>
          </div>

          <div className={styles.publishedAcumen}>
            <p className={styles.title}>現在公開中のACUMEN</p>

            <div className={styles.acumens}>
              <img src={accentLogo2} alt="" className={styles.accentBgLeft} />
              <div className={styles.acumenBlock}>
                <img src={acumen1} alt="" width={246} />
                <p className={styles.acumenBlockText}>
                  Kamilla Miskowiak 教授
                  <br /> （コペンハーゲン大学、デンマーク）
                </p>
              </div>
              <div className={styles.acumenBlock}>
                <img src={acumen2} alt="" width={246} />
                <p className={styles.acumenBlockText}>
                  Gin S. Malhi 教授
                  <br /> （シドニー大学、オーストラリア）
                </p>
              </div>
              <div className={styles.acumenBlock}>
                <img src={acumen3} alt="" width={246} />
                <p className={styles.acumenBlockText}>
                  Richard S.E. Keefe 教授
                  <br /> （デューク大学、アメリカ）
                </p>
              </div>
              <img src={accentLogo2} alt="" className={styles.accentBgRight} />
            </div>
          </div>

          <div className={styles.bottom}>
            <p className={styles.subText}>
              今後順次動画配信されますので認知機能に着目することの重要性、
              <br />
              日常診療での認知機能との向き合い方をエキスパートと共に一緒に考えましょう。
            </p>

            <Link to="/video">
              <span className={styles.button}>
                Cognition Cafe Reserveを見る
              </span>
            </Link>
          </div>
        </div>
      </Accordion>
    </div>
  )
}
