import { Link } from 'gatsby'
import React from 'react'
import { Accordion } from '../general/accordion'
import styles from './about.module.scss'
import postMark from '@/images/postmark.png'
import booksImage1 from '@/images/slide-image/book-1.png'
import booksImage2 from '@/images/slide-image/book-2.png'

export const About: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Accordion title={'コグカフェ便り'}>
        <div className={styles.menuBody}>
          <p className={styles.menuDescription}>
            <span className={styles.headText}>
              認知機能改善に携わる多くの方々に、認知機能に関する情報を知って頂くための書籍・文献のご紹介ページです。
            </span>
            精神科医師・リハビリ医師・看護師・心理士・ケアスタッフなど認知機能改善に携わる多くの方々に、認知機能をより知って頂くために認知機能に関する書籍や文献をご紹介しております。
          </p>

          <img
            src={postMark}
            alt=""
            width={204.24}
            height={204.24}
            className={styles.postmark}
          />

          <div className={styles.introduceBooks}>
            <p className={styles.title}>ご紹介中の書籍</p>

            <div className={styles.books}>
              <div className={styles.bookBlock}>
                <img src={booksImage1} alt="" />
                <p className={styles.bookDescription}>
                  <span className={styles.bookTitle}>
                    統合失調症患者と家族が選ぶ社会復帰をめざす認知矯正療法
                  </span>
                  <span className={styles.author}>
                    著者：高橋太郎ほか 出典：幻冬舎メディアコンサルティング
                  </span>
                  統合失調症の患者さんの社会復帰を後押しする、認知矯正療法の詳細について実際の治療の様子や治療の効果を高めるメンタルケアや患者さんとの接し方などについて解説されています。
                </p>
              </div>

              <div className={styles.bookBlock2}>
                <p className={styles.bookDescription}>
                  <span className={styles.bookTitle}>
                    メタ認知トレーニングをはじめよう！MCTガイドブック
                  </span>
                  <span className={styles.author}>
                    著者：石垣琢磨 出典：星和書店
                  </span>
                  「基礎編」と「実践編」に分け、「基礎編」でMCTと認知行動療法の基礎、統合失調症やうつ病、その他の各疾患への導入について、「実践編」で様々な職種のトレーナーが日常臨床における実践法を解説しています。
                </p>
                <img src={booksImage2} alt="" />
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <span className={styles.subText}>
              TOPページでも最新情報をお届けしておりますので是非ご覧ください。
            </span>
            <Link to="/#books">
              <span className={styles.button}>コグカフェ便りを見る</span>
            </Link>
          </div>
        </div>
      </Accordion>
    </div>
  )
}
