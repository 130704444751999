import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import React from 'react'
import Seo from '../components/general/seo'
import Layout from '../components/layout/layout'
import styles from './grandMenu.module.scss'
import { About } from '@/components/grandMenu/about'
import { NewVisit } from '@/components/grandMenu/newVisit'
import { PublicContents } from '@/components/grandMenu/publicContents'
import SectionTitle from '@/components/top/section-title'
import { BreadcrumbContext } from '@/types/breadcrumb'

const GrandMenu: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo title="Grand Menu" />
      <div className={styles.wrap}>
        <h1>
          <SectionTitle theme="dark">Cognition Cafe Grand Menu</SectionTitle>
        </h1>

        <div className={styles.body}>
          <div className={styles.menuLists}>
            <NewVisit />
            <PublicContents />
            <About />
          </div>
        </div>

        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" > "
          crumbLabel={'Cognition cafe Grand Menu'}
        />
      </div>
    </Layout>
  )
}

export default GrandMenu
