import clsx from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'
import React from 'react'
import styles from './accordion.module.scss'
import readMoreIcon from '@/images/icon-more.svg'

type AccordionProps = {
  children: ReactNode
  title: string
}

export const Accordion: React.FC<AccordionProps> = ({ children, title }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const [windowScrollHeight, setWindowScrollHeight] = useState(
    ref.current?.scrollHeight
  )

  console.log(children)

  useEffect(() => {
    const onResize = () => {
      setWindowScrollHeight(ref.current?.scrollHeight)
      console.log('windowScrollHeight')
      console.log(ref.current?.scrollHeight)
    }

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const expandedStyle = {
    height: windowScrollHeight ?? ref.current?.scrollHeight,
    overflow: 'visible',
  }

  return (
    <>
      <button
        className={styles.menuTitle}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={styles.menuBoxIcon} />
        <span className={styles.menuText}>{title}</span>
        <img
          src={readMoreIcon}
          className={clsx(
            styles.expandedArrow,
            isExpanded ? styles.rotateArrow : ''
          )}
        />
      </button>

      <div
        style={isExpanded ? { ...expandedStyle } : { height: 0 }}
        className={styles.menuItem}
      >
        <div ref={ref}>{children}</div>
      </div>
    </>
  )
}
