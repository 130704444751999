import clsx from 'clsx'
import React from 'react'
import { Accordion } from '../general/accordion'
import styles from './newVisit.module.scss'
import icon from '@/images/grand-menu-new-visit-column.png'

export const NewVisit: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Accordion title={'Cognition-Starter'}>
        <div className={styles.menuBody}>
          <p className={styles.newVisitMenuDescription}>
            Cognition Cafeにお越しいただきありがとうございます。
            <br />
            <br />
            皆さんは「認知機能が低下する」という言葉を聞いて、何を思い浮かべますか？
            <br />
            <br />
            多くの方は「認知症」とともに、「物忘れが多くなる」「日付や曜日を思い出せなくなる」など、主に記憶の障害を思い浮かべるのではないでしょうか。
            <br />
            <br />
            または、「高齢者に多い」「脳の老化現象」など、年齢に伴い進行するものとお考えになるかもしれません。
            <br />
            <br />
            しかしながら、「認知機能」とは、さらに広く、そして身近なものなのです。
            <br />
            <br />
            Cognition
            Cafeとは、医療従事者のみなさんに認知機能【Cognition】を「知り」「学び」「考える」きっかけをお届けするオンラインカフェスペースです。
            <br />
            <br />
            2022年8月30日の開設以来、専門医の先生方を始め多くの医療関係者の方にご覧頂いております。このGrand
            Menuでは、Cognition
            Cafeで過ごすお時間をより有意義なものにして頂けるよう、また認知機能について知って頂き、学び、考えるきっかけとなるような情報をまとめています。
            <br />
            <br />
            ぜひご覧いただき、一緒にCognition Cafeを盛り上げて行きましょう。
          </p>

          <div className={styles.column}>
            <div className={styles.columnBlock}>
              <p className={styles.columnTitle}>
                <span className={styles.beforeBorderLeft} />
                認知機能【Cognition】とは？
              </p>
              <div className={styles.columnText}>
                <span className={styles.columnImportantText}>
                  物事を正しく理解し、適切に実行するための脳の機能です。また相手の考えを理解する、気持ちを察するなど社会生活にも関連しています。
                </span>
                <ul className={clsx('mt-10', styles.columnSymptomList)}>
                  <li>感覚と知覚： 見たもの、触ったものを認識する</li>
                  <li>運動能力： 手や足、体全体を動かす</li>
                  <li>注意と集中： 一つのことを続ける、話を聞く</li>
                  <li>記憶： 物事を記憶し、思い出す</li>
                  <li>遂行機能： 物事を計画的に行う</li>
                  <li>処理速度： 情報を理解し、行動に移す</li>
                  <li>言語能力： 人の言葉を理解し、言葉で自分の意思を伝える</li>
                  <li>
                    社会認知機能：
                    相手の顔や声色から感情を読み取る、相手の意図を推測する
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.columnBlock}>
              <p className={styles.columnTitle}>
                <span className={styles.beforeBorderLeft} />
                認知機能はなぜ低下する？
              </p>
              <div className={styles.columnText}>
                <span className={styles.columnImportantText}>
                  疾患や加齢のみならず、日常生活にも原因が潜んでおり、それぞれが複合的に影響を及ぼします。
                </span>

                <ul className={clsx('mt-10', styles.columnSymptomList)}>
                  <li>
                    疾患の罹患
                    <br />
                    統合失調症、双極症、うつ病、発達障害、脳梗塞などの脳の疾患
                    <br />
                    パーキンソン病やアルツハイマー型認知症などの神経変性疾患
                    <br />
                    感染症 など
                  </li>
                  <li>
                    特定の種類の薬剤の投与
                    <br />
                    抗コリン作用を持つ薬剤、ベンゾジアゼピン系薬剤 など
                  </li>
                  <li>加齢</li>
                  <li>睡眠障害 </li>
                  <li>食事の偏り</li>
                </ul>
              </div>
            </div>

            <div className={styles.columnBlock}>
              <p className={styles.columnTitle}>
                <span className={styles.beforeBorderLeft} />
                認知機能はなぜ重要？
              </p>
              <div className={styles.columnText}>
                <span className={styles.columnImportantText}>
                  脳の各機能を制御して、
                  日常生活をよりよく過ごすためには欠かすことができないものです。
                </span>
                <p className="mt-10">
                  医療の観点では、精神疾患の治療ターゲットとして、薬物治療ばかりでなくリハビリテーション領域でも注目されており、医療従事者が正しく理解し、質の高い医療の提供が望まれます。当事者の日常生活を改善するために必要な専門職の役割や知見を学ぶことはとても重要です。
                </p>
              </div>
            </div>

            <div className={styles.columnConclusion}>
              <p>
                本サイトが精神科医師・リハビリ医師・看護師・心理士・ケアスタッフなど認知機能改善に携わる多くの方々やこれまで「認知機能」になじみのない方々へ認知機能を知っていただくきっかけとなれば幸いです。
              </p>
              <img src={icon} alt="" />
            </div>
          </div>

          <div className={styles.referenceLiteratureWrapper}>
            <p className={styles.title}>参考文献</p>
            <ul className={styles.litetaturesList}>
              <li>Harvey PD. Dialogues Clin Neurosci. 2019;21:227-237.</li>
              <li>
                水上勝義 薬剤による認知機能障害
                第105回日本精神神経学会総会シンポジウム
              </li>
              <li>
                橋本 通子ら.スクリーニングテストからみた認知機能と食生活の関連.
                日本未病学会雑誌. 2022;28:34-42.
              </li>
              <li>
                Uchino T, et al. Psychiatry and Clinical Neurosciences.
                2022:76;429–436.
              </li>
              <li>Fortier-Brochu E, et al. Sleep Med Rev. 2012;16:83-94.</li>
              <li>
                こころの病気を知る（厚生労働省）
                https://www.mhlw.go.jp/kokoro/know/index.html
              </li>
            </ul>
          </div>
        </div>
      </Accordion>
    </div>
  )
}
